.clients-controls__row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 3px 20px; }

div.clients-controls__client-search-input-form {
  padding-top: 1px;
  width: 400px; }

@media only screen and (max-width: 640px) {
  .clients-controls__row {
    padding: 10px 20px;
    flex-direction: column;
    align-items: flex-start; } }
